lx-app {
  display: block; }

.key-value {
  display: table; }
  .key-value > * {
    display: table-row; }
    .key-value > * > * {
      display: table-cell;
      padding: 3px; }

.buttons {
  text-align: right; }
