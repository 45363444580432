[data-row] {
  display: flex; }
  [data-row],
  [data-row] * {
    box-sizing: border-box; }
  [data-row] [data-col] {
    flex: 0 0 auto; }
  [data-row] [data-col~='left'] {
    align-self: flex-start; }
  [data-row] [data-col~='middle'] {
    align-self: center; }
  [data-row] [data-col~='right'] {
    align-self: flex-end; }
  [data-row] [data-col~='auto'] {
    flex: 1 0 auto; }
  [data-row] [data-col~='1'] {
    flex: 0 0 8.33333%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-1'] {
      flex: 0 0 8.33333%; } }
  [data-row] [data-col~='2'] {
    flex: 0 0 16.66667%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-2'] {
      flex: 0 0 16.66667%; } }
  [data-row] [data-col~='3'] {
    flex: 0 0 25%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-3'] {
      flex: 0 0 25%; } }
  [data-row] [data-col~='4'] {
    flex: 0 0 33.33333%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-4'] {
      flex: 0 0 33.33333%; } }
  [data-row] [data-col~='5'] {
    flex: 0 0 41.66667%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-5'] {
      flex: 0 0 41.66667%; } }
  [data-row] [data-col~='6'] {
    flex: 0 0 50%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-6'] {
      flex: 0 0 50%; } }
  [data-row] [data-col~='7'] {
    flex: 0 0 58.33333%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-7'] {
      flex: 0 0 58.33333%; } }
  [data-row] [data-col~='8'] {
    flex: 0 0 66.66667%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-8'] {
      flex: 0 0 66.66667%; } }
  [data-row] [data-col~='9'] {
    flex: 0 0 75%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-9'] {
      flex: 0 0 75%; } }
  [data-row] [data-col~='10'] {
    flex: 0 0 83.33333%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-10'] {
      flex: 0 0 83.33333%; } }
  [data-row] [data-col~='11'] {
    flex: 0 0 91.66667%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-11'] {
      flex: 0 0 91.66667%; } }
  [data-row] [data-col~='12'] {
    flex: 0 0 100%; }
  @media (min-width: 1440px) {
    [data-row] [data-col~='lg-12'] {
      flex: 0 0 100%; } }
  [data-row] [data-col~='1'] {
    flex: 0 0 8.33333%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-1'] {
      flex: 0 0 8.33333%; } }
  [data-row] [data-col~='2'] {
    flex: 0 0 16.66667%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-2'] {
      flex: 0 0 16.66667%; } }
  [data-row] [data-col~='3'] {
    flex: 0 0 25%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-3'] {
      flex: 0 0 25%; } }
  [data-row] [data-col~='4'] {
    flex: 0 0 33.33333%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-4'] {
      flex: 0 0 33.33333%; } }
  [data-row] [data-col~='5'] {
    flex: 0 0 41.66667%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-5'] {
      flex: 0 0 41.66667%; } }
  [data-row] [data-col~='6'] {
    flex: 0 0 50%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-6'] {
      flex: 0 0 50%; } }
  [data-row] [data-col~='7'] {
    flex: 0 0 58.33333%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-7'] {
      flex: 0 0 58.33333%; } }
  [data-row] [data-col~='8'] {
    flex: 0 0 66.66667%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-8'] {
      flex: 0 0 66.66667%; } }
  [data-row] [data-col~='9'] {
    flex: 0 0 75%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-9'] {
      flex: 0 0 75%; } }
  [data-row] [data-col~='10'] {
    flex: 0 0 83.33333%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-10'] {
      flex: 0 0 83.33333%; } }
  [data-row] [data-col~='11'] {
    flex: 0 0 91.66667%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-11'] {
      flex: 0 0 91.66667%; } }
  [data-row] [data-col~='12'] {
    flex: 0 0 100%; }
  @media (min-width: 1024px) {
    [data-row] [data-col~='md-12'] {
      flex: 0 0 100%; } }
  [data-row] [data-col~='1'] {
    flex: 0 0 8.33333%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-1'] {
      flex: 0 0 8.33333%; } }
  [data-row] [data-col~='2'] {
    flex: 0 0 16.66667%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-2'] {
      flex: 0 0 16.66667%; } }
  [data-row] [data-col~='3'] {
    flex: 0 0 25%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-3'] {
      flex: 0 0 25%; } }
  [data-row] [data-col~='4'] {
    flex: 0 0 33.33333%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-4'] {
      flex: 0 0 33.33333%; } }
  [data-row] [data-col~='5'] {
    flex: 0 0 41.66667%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-5'] {
      flex: 0 0 41.66667%; } }
  [data-row] [data-col~='6'] {
    flex: 0 0 50%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-6'] {
      flex: 0 0 50%; } }
  [data-row] [data-col~='7'] {
    flex: 0 0 58.33333%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-7'] {
      flex: 0 0 58.33333%; } }
  [data-row] [data-col~='8'] {
    flex: 0 0 66.66667%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-8'] {
      flex: 0 0 66.66667%; } }
  [data-row] [data-col~='9'] {
    flex: 0 0 75%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-9'] {
      flex: 0 0 75%; } }
  [data-row] [data-col~='10'] {
    flex: 0 0 83.33333%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-10'] {
      flex: 0 0 83.33333%; } }
  [data-row] [data-col~='11'] {
    flex: 0 0 91.66667%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-11'] {
      flex: 0 0 91.66667%; } }
  [data-row] [data-col~='12'] {
    flex: 0 0 100%; }
  @media (min-width: 768px) {
    [data-row] [data-col~='sm-12'] {
      flex: 0 0 100%; } }
  [data-row] [data-col~='1'] {
    flex: 0 0 8.33333%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-1'] {
      flex: 0 0 8.33333%; } }
  [data-row] [data-col~='2'] {
    flex: 0 0 16.66667%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-2'] {
      flex: 0 0 16.66667%; } }
  [data-row] [data-col~='3'] {
    flex: 0 0 25%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-3'] {
      flex: 0 0 25%; } }
  [data-row] [data-col~='4'] {
    flex: 0 0 33.33333%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-4'] {
      flex: 0 0 33.33333%; } }
  [data-row] [data-col~='5'] {
    flex: 0 0 41.66667%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-5'] {
      flex: 0 0 41.66667%; } }
  [data-row] [data-col~='6'] {
    flex: 0 0 50%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-6'] {
      flex: 0 0 50%; } }
  [data-row] [data-col~='7'] {
    flex: 0 0 58.33333%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-7'] {
      flex: 0 0 58.33333%; } }
  [data-row] [data-col~='8'] {
    flex: 0 0 66.66667%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-8'] {
      flex: 0 0 66.66667%; } }
  [data-row] [data-col~='9'] {
    flex: 0 0 75%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-9'] {
      flex: 0 0 75%; } }
  [data-row] [data-col~='10'] {
    flex: 0 0 83.33333%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-10'] {
      flex: 0 0 83.33333%; } }
  [data-row] [data-col~='11'] {
    flex: 0 0 91.66667%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-11'] {
      flex: 0 0 91.66667%; } }
  [data-row] [data-col~='12'] {
    flex: 0 0 100%; }
  @media (min-width: 0px) {
    [data-row] [data-col~='xs-12'] {
      flex: 0 0 100%; } }

[data-row~='right'] {
  justify-content: flex-end; }

[data-row~='left'] {
  justify-content: flex-start; }

[data-row~='center'] {
  justify-content: center; }

[data-row~='top'] {
  align-items: flex-start; }

[data-row~='middle'] {
  align-items: center; }

[data-row~='baseline'] {
  align-items: baseline; }

[data-row~='stretch'] {
  align-items: stretch; }

[data-row~='bottom'] {
  align-items: flex-end; }

[data-row~='reverse'] {
  flex-direction: row-reverse; }

[data-row~='vertical'] {
  flex-direction: column; }

[data-row~='wrap'] {
  flex-wrap: wrap; }

@media (min-width: 1440px) {
  [data-row~='lg-right'] {
    justify-content: flex-end; } }

@media (min-width: 1440px) {
  [data-row~='lg-left'] {
    justify-content: flex-start; } }

@media (min-width: 1440px) {
  [data-row~='lg-center'] {
    justify-content: center; } }

@media (min-width: 1440px) {
  [data-row~='lg-top'] {
    align-items: flex-start; } }

@media (min-width: 1440px) {
  [data-row~='lg-middle'] {
    align-items: center; } }

@media (min-width: 1440px) {
  [data-row~='lg-baseline'] {
    align-items: baseline; } }

@media (min-width: 1440px) {
  [data-row~='lg-stretch'] {
    align-items: stretch; } }

@media (min-width: 1440px) {
  [data-row~='lg-bottom'] {
    align-items: flex-end; } }

@media (min-width: 1440px) {
  [data-row~='lg-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1440px) {
  [data-row~='lg-vertical'] {
    flex-direction: column; } }

@media (min-width: 1440px) {
  [data-row~='lg-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 1024px) {
  [data-row~='md-right'] {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  [data-row~='md-left'] {
    justify-content: flex-start; } }

@media (min-width: 1024px) {
  [data-row~='md-center'] {
    justify-content: center; } }

@media (min-width: 1024px) {
  [data-row~='md-top'] {
    align-items: flex-start; } }

@media (min-width: 1024px) {
  [data-row~='md-middle'] {
    align-items: center; } }

@media (min-width: 1024px) {
  [data-row~='md-baseline'] {
    align-items: baseline; } }

@media (min-width: 1024px) {
  [data-row~='md-stretch'] {
    align-items: stretch; } }

@media (min-width: 1024px) {
  [data-row~='md-bottom'] {
    align-items: flex-end; } }

@media (min-width: 1024px) {
  [data-row~='md-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 1024px) {
  [data-row~='md-vertical'] {
    flex-direction: column; } }

@media (min-width: 1024px) {
  [data-row~='md-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 768px) {
  [data-row~='sm-right'] {
    justify-content: flex-end; } }

@media (min-width: 768px) {
  [data-row~='sm-left'] {
    justify-content: flex-start; } }

@media (min-width: 768px) {
  [data-row~='sm-center'] {
    justify-content: center; } }

@media (min-width: 768px) {
  [data-row~='sm-top'] {
    align-items: flex-start; } }

@media (min-width: 768px) {
  [data-row~='sm-middle'] {
    align-items: center; } }

@media (min-width: 768px) {
  [data-row~='sm-baseline'] {
    align-items: baseline; } }

@media (min-width: 768px) {
  [data-row~='sm-stretch'] {
    align-items: stretch; } }

@media (min-width: 768px) {
  [data-row~='sm-bottom'] {
    align-items: flex-end; } }

@media (min-width: 768px) {
  [data-row~='sm-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 768px) {
  [data-row~='sm-vertical'] {
    flex-direction: column; } }

@media (min-width: 768px) {
  [data-row~='sm-wrap'] {
    flex-wrap: wrap; } }

@media (min-width: 0px) {
  [data-row~='xs-right'] {
    justify-content: flex-end; } }

@media (min-width: 0px) {
  [data-row~='xs-left'] {
    justify-content: flex-start; } }

@media (min-width: 0px) {
  [data-row~='xs-center'] {
    justify-content: center; } }

@media (min-width: 0px) {
  [data-row~='xs-top'] {
    align-items: flex-start; } }

@media (min-width: 0px) {
  [data-row~='xs-middle'] {
    align-items: center; } }

@media (min-width: 0px) {
  [data-row~='xs-baseline'] {
    align-items: baseline; } }

@media (min-width: 0px) {
  [data-row~='xs-stretch'] {
    align-items: stretch; } }

@media (min-width: 0px) {
  [data-row~='xs-bottom'] {
    align-items: flex-end; } }

@media (min-width: 0px) {
  [data-row~='xs-reverse'] {
    flex-direction: row-reverse; } }

@media (min-width: 0px) {
  [data-row~='xs-vertical'] {
    flex-direction: column; } }

@media (min-width: 0px) {
  [data-row~='xs-wrap'] {
    flex-wrap: wrap; } }
