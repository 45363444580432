// http://sassdoc.com/annotations/
@import 'variables';

/// @param {string} $breakpoint - Name of a breakpoint
/// Create a block that responds to the pre-defined breakpoint
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else {
    @warn "No breakpoint for `#{$breakpoint}`.";
  }
}
