* {
  box-sizing: border-box;
  font-size: inherit;
}

body,
html {
  color: #777777;
  font-family: helvetica, sans-serif;
  font-size: 12px;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

a {

  &:visited {

  }

  &:hover {

  }

  &:active {

  }

  &:focus {

  }
}

h1 { font-size: 3.2em; }
h2 { font-size: 2.4em; }
h3 { font-size: 2.1em; }
h4 { font-size: 1.6em; }
h5 { font-size: 1.5em; }
h6 { font-size: 1.4em; }

p  { font-size: 1.1em; }
button { font-size: 1.1em; }
